import {useFormikContext} from 'formik';
import React, {useEffect, useRef, useState} from 'react';
import {addAppliance} from 'service';
import {NewApplianceForm, useConfirmationDialog} from 'shared';

export const useAppliance = (type: number, name: string) => {
    const {setFieldValue} = useFormikContext();
    const {dialog, showDialog, hideDialog, setMessage} =
        useConfirmationDialog();
    const newAppliance = useRef({
        make: '',
        model: '',
        height: 0,
        width: 0,
        depth: 0,
    });
    const [messages, setMessages] = useState([]);
    const [loader, setLoader] = useState(false);

    useEffect(() => {
        setMessage(
            <NewApplianceForm newAppliance={newAppliance} messages={messages} />
        );
    }, [messages]);

    const buttonClickHandher = () => {
        showDialog({
            title: 'Add new Appliance',
            message: (
                <NewApplianceForm
                    newAppliance={newAppliance}
                    messages={messages}
                />
            ),
            hideYesButton: true,
            hideNoButton: true,
            buttons: [
                {
                    name: 'Save',
                    variant: 'primary',
                    action: async () => {
                        if (newAppliance.current.make === '') {
                            setMessages(['Please enter appliance make']);

                            return;
                        }

                        if (newAppliance.current.model === '') {
                            setMessages(['Please enter a model']);

                            return;
                        }

                        if (newAppliance.current.height <= 0) {
                            setMessages(['Please enter a valid height']);

                            return;
                        }

                        if (newAppliance.current.width <= 0) {
                            setMessages(['Please enter a valid width']);

                            return;
                        }

                        if (newAppliance.current.depth <= 0) {
                            setMessages(['Please enter a valid depth']);

                            return;
                        }

                        setLoader(true);
                        const newApplianceId = (await addAppliance({
                            ...newAppliance.current,
                            ...{type},
                        })) as number;

                        void setFieldValue(name, newApplianceId);

                        newAppliance.current = {
                            make: '',
                            model: '',
                            height: 0,
                            width: 0,
                            depth: 0,
                        };
                        setMessages([]);
                        setLoader(false);
                        hideDialog();
                    },
                    show: true,
                    controlledHideDialog: true,
                },
                {
                    name: 'Cancel',
                    variant: 'warning',
                    show: true,
                },
            ],
        });
    };

    return {
        loader,
        dialog,
        buttonClickHandher,
    };
};
